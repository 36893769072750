<template>
  <div class="page-gray">
    <van-sticky>
      <van-search
          v-model="keyword"
          shape="round"
          background="#fff"
          placeholder="搜索学校"
          @input="onSearch"
      />
    </van-sticky>
    <van-index-bar ref="indexBar" :sticky="!hidden" :sticky-offset-top="54" :index-list="indexList">
      <div v-for="(value, key) in listData" :style="{height: isLast(key) ? '100vh' :'' }">
        <van-index-anchor :index="key"/>
        <van-cell v-for="item in value" :title="item.name" :id="`school${item.id}`"
                  @click="switchSchools(item)"/>
      </div>
    </van-index-bar>

  </div>
</template>

<script>
import Vue from "vue";
import pinyin from "js-pinyin";
import {mapMutations} from "vuex";

let word = ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K", "L", "M", "N", "O", "P", "Q", "R", "S", "T", "U", "V", "W", "X", "Y", "Z"]

export default {
  name: "SchollList",
  data() {
    return {
      hidden: false,
      keyword: '',
      indexList: word,
      listData: {}, //显示的
      schoolList: [],
    }
  },
  created() {
    this.querySchoolData();
  },
  methods: {
    ...mapMutations(['setStudentArr', 'setStudentInfo']),
    isLast(key) {
      let keys = Object.keys(this.listData);
      return keys[keys.length - 1] === key
    },
    onSearch() {
      //查询学校数据
      this.$http.get(`/mall/grade/querySchoolByDeptId`, {params: {name: this.keyword, deptId: Vue.prototype.deptId}}, {
        emulateJSON: true
      }).then(res => {
        this.schoolList = res.data;
        this.sortSchoolData();
      });
    },
    //查找学校数据
    querySchoolData() {
      //查询学校数据
      this.$http.get(`/mall/grade/querySchoolByDeptId`, {params: {deptId: Vue.prototype.deptId}}, {
        emulateJSON: true
      }).then(res => {
        this.schoolList = res.data;
        this.sortSchoolData();
      });
    },
    sortSchoolData() {
      let data = {
        A: [],
        B: [],
        C: [],
        D: [],
        E: [],
        F: [],
        G: [],
        H: [],
        I: [],
        J: [],
        K: [],
        L: [],
        M: [],
        N: [],
        O: [],
        P: [],
        Q: [],
        R: [],
        S: [],
        T: [],
        U: [],
        V: [],
        W: [],
        X: [],
        Y: [],
        Z: []
      };
      this.schoolList.forEach(school => {
        let startPY = pinyin.getFullChars(school.name)[0];
        let arr = data[startPY];
        if(arr){
          arr.push(school);
        }
      });
      this.listData = data;
    },
    //切换学校
    switchSchools(item) {
      this.$dialog.confirm({
        message: '确认切换学校',
      }).then(() => {
        // on confirm
        //查看该学校下是否有学生 有就取第一个学生
        this.$http.get(`/mall/h5_mall/queryStudentBySchoolId`, {
          params: {
            schoolId: item.id,
            userId: Vue.prototype.userId
          }
        }, {
          emulateJSON: true
        }).then(res => {
          if (res.data.length > 0) {
            let data = res.data;
            let studentInfo = data[0];
            this.setStudentInfo(studentInfo);
            //添加默认学生
            this.$http.post(this.baseMallUrl + `/h5_mall/switchDefaultStudents/${studentInfo.id}`, {}, {emulateJSON: true}).then(() => {
              //操作成功 获取学校信息
              this.$http.get(this.baseMallUrl + `/buyergrade/getStudentInfoByUserId/${Vue.prototype.userId}`).then(res => {
                this.setStudentArr(res.data);
                this.$router.push({path: '/'})
              })
            })
          } else {
            this.$router.push({path: '/student/add', query: {schoolObj: item}})
          }
        });
      })
      .catch(() => {
        // on cancel
      });

    }
  },
}

</script>
<style lang="less" scoped>
::v-deep .van-index-bar__index {
  color: rgba(0, 0, 0, .6);
  font-size: 10px;
  line-height: 14px;
  margin: 1px 16px;
  padding: 0;
}

::v-deep .van-index-bar__index--active {
  width: 14px;
  height: 14px;
  background: #FF553E;
  border-radius: 50%;
  color: #fff;

}

::v-deep .van-index-anchor {
  height: 27px;
  line-height: 27px;
  color: #000;
  font-size: 10px;
  background-color: #F4F4F4;
}

::v-deep .van-cell__title span {
  font-size: 14px;
  font-weight: 500;
  color: #000;
}
</style>
